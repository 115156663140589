"use client";
import styles from "../../guest.module.css";
import loginStyles from "./loginForm.module.css";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { Button } from "@/components/atoms";
import Input from "@/components/molecules/NewForm/Input";
import { useLogin } from "@/lib/hooks/useSelection";
import { LoginStoryblok } from "@/types/storyblok-blok-types";
import { StoryblokStory } from "storyblok-generate-ts";

import Link from "@/lib/bloks/atoms/Link";
import { useValidation } from "@/lib/hooks/useValidation";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";

// Tried to use zod but there is some problem with current version of it: https://github.com/colinhacks/zod/issues/2697
// import { z } from "zod";

// const loginSchema = z.object({
//   email: z.string(),
//   password: z.string()
// });

const defaultValues = {
  email: "",
  password: "",
  rememberMe: false
};

interface ILogInFormProps {
  pageData: StoryblokStory<LoginStoryblok>;
}

export const LogInForm = ({ pageData }: ILogInFormProps) => {
  const { login: loginFunction } = useLogin();
  const [loading, setLoading] = useState(false);

  const validation = useValidation();

  const errorMessage = loginFunction.error?.message;

  useEffect(() => {
    if (errorMessage) {
      setLoading(false);
    }
  }, [errorMessage]);

  const schema = validation
    .object({
      email: validation.string().email().required(),
      password: validation.string().required(),
      rememberMe: validation.boolean().optional()
    })
    .required();

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });

  const { handleSubmit } = form;

  const onSubmit = handleSubmit((formData) => {
    setLoading(true);
    loginFunction.mutate({
      email: formData.email,
      password: formData.password
    });
  });

  const register = pageData.content.register?.[0];
  const resetPassword = pageData.content.resetPassword?.[0];

  const t = useTranslations();

  return (
    <div className={clsx(styles.formWrapper, loginStyles.formWrapper)}>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Input
            name="email"
            label={t("common.fields.email")}
            inputProps={{ type: "email" }}
          />

          <Input
            name="password"
            label={t("common.fields.password")}
            inputProps={{ type: "password" }}
          />

          <div className={loginStyles.loginOptions}>
            {/* TODO: implement "remember me" functionality */}
            <div style={{ height: "30px" }} />
            {/* https://app.asana.com/0/1205973921554669/1206070547307313/f 
            <Checkbox
              name="rememberMe"
              label={t("myAccount.loginPage.fields.rememberMe")}
              inputProps={{ disabled: true }}
            />*/}

            {resetPassword && (
              <Link blok={resetPassword} key={resetPassword._uid} />
            )}
          </div>

          <Button isLoading={loading} color="black" type="submit">
            {t("myAccount.loginPage.button")}
          </Button>

          {errorMessage && <div className="notification">{errorMessage}</div>}

          {register && (
            <div className={styles.linkWrapper}>
              <span>{t("myAccount.loginPage.notMember")}</span>

              <Link blok={register} key={register._uid} />
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
